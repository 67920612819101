'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Axios = require("axios");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var H1$BycratesPlatformUi = require("../src-ui/Headings/H1.bs.js");
var H5$BycratesPlatformUi = require("../src-ui/Headings/H5.bs.js");
var Card$BycratesPlatformUi = require("../src-ui/Card/Card.bs.js");
var Button$BycratesPlatformUi = require("../src-ui/Button/Button.bs.js");
var Loader$BycratesPlatformUi = require("../src-ui/Loader/Loader.bs.js");
var RadioGroup$BycratesPlatformUi = require("../src-ui/Radio/RadioGroup.bs.js");
var JsonDictUtils$BycratesPlatformUi = require("../src-ui/Utils/JsonDictUtils.bs.js");

function FormRender(Props) {
  var token = Props.token;
  var match = React.useState((function () {
          return /* Loading */0;
        }));
  var setFormState = match[1];
  var formState = match[0];
  var match$1 = React.useState((function () {
          return /* [] */0;
        }));
  var setMissingCards = match$1[1];
  var missingCards = match$1[0];
  var match$2 = React.useState((function () {
          return { };
        }));
  var setUserState = match$2[1];
  var userState = match$2[0];
  var match$3 = React.useState((function () {
          return false;
        }));
  var setSubmitted = match$3[1];
  React.useEffect((function () {
          Axios.get("/api/form_session/" + token).then((function (res) {
                    return Promise.resolve(Curry._1(setFormState, (function (param) {
                                      return /* Loaded */Block.__(0, [res.data.data]);
                                    })));
                  })).catch((function (param) {
                  return Promise.resolve(Curry._1(setFormState, (function (param) {
                                    return /* NotFound */1;
                                  })));
                }));
          return ;
        }), ([]));
  var onSubmit = function (e) {
    e.preventDefault();
    Curry._1(setSubmitted, (function (param) {
            return true;
          }));
    if (typeof formState === "number" || formState.tag) {
      return /* () */0;
    } else {
      var x = formState[0];
      var missingOnSubmit = $$Array.map((function (field) {
              var dictField = JsonDictUtils$BycratesPlatformUi.get(userState, String(field.id));
              if (!field.optional && (dictField === undefined || Caml_obj.caml_equal(dictField, ""))) {
                return field.id;
              }
              
            }), x.fields);
      if (Belt_Array.keepMap(missingOnSubmit, (function (a) {
                return a;
              })).length !== 0) {
        Curry._1(setMissingCards, (function (param) {
                return $$Array.to_list(missingOnSubmit);
              }));
        return Curry._1(setSubmitted, (function (param) {
                      return false;
                    }));
      } else {
        Curry._1(setMissingCards, (function (param) {
                return /* [] */0;
              }));
        var body = $$Array.map((function (answerKey) {
                return {
                        formFieldId: Caml_format.caml_int_of_string(answerKey),
                        value: JsonDictUtils$BycratesPlatformUi.get(userState, answerKey),
                        answerType: "yesno",
                        optional: false
                      };
              }), Object.keys(userState));
        Axios.post("/api/form_session/" + token, {
                answers: body
              }).then((function (param) {
                return Promise.resolve(Curry._1(setFormState, (function (param) {
                                  return /* Finished */Block.__(1, [x]);
                                })));
              }));
        return /* () */0;
      }
    }
  };
  var logoOrHeader = function (x) {
    var match = x.logo;
    if (match == null) {
      return React.createElement(H1$BycratesPlatformUi.make, {
                  children: x.friendlyName
                });
    } else {
      return React.createElement("img", {
                  className: "h-16 m-auto mb-4 mt-12 md:mt-6",
                  src: match
                });
    }
  };
  if (typeof formState === "number") {
    if (formState === /* Loading */0) {
      return React.createElement(Loader$BycratesPlatformUi.make, { });
    } else {
      return React.createElement(H1$BycratesPlatformUi.make, {
                  children: "Ingen skjema funnet"
                });
    }
  } else if (formState.tag) {
    return React.createElement("div", {
                className: "mb-8"
              }, logoOrHeader(formState[0]), React.createElement(H1$BycratesPlatformUi.make, {
                    light: true,
                    className: "my-12 text-gray-800",
                    children: "Besvarelsen din har blitt registrert"
                  }));
  } else {
    var x = formState[0];
    return React.createElement(React.Fragment, undefined, React.createElement("div", {
                    className: "mb-8"
                  }, logoOrHeader(x), React.createElement(H1$BycratesPlatformUi.make, {
                        light: true,
                        children: "COVID-19 kontrollskjema"
                      })), React.createElement("form", {
                    onSubmit: onSubmit
                  }, React.createElement("div", {
                        className: "space-y-4"
                      }, $$Array.map((function (field) {
                              var match = field.answerType;
                              var tmp = match === "yesno" ? React.createElement(RadioGroup$BycratesPlatformUi.make, {
                                      inline: true,
                                      name: String(field.id),
                                      boxes: /* :: */[
                                        {
                                          value: "yes",
                                          label: "Ja"
                                        },
                                        /* :: */[
                                          {
                                            value: "no",
                                            label: "Nei"
                                          },
                                          /* [] */0
                                        ]
                                      ],
                                      setValue: (function (id) {
                                          var name = String(field.id);
                                          var value = id;
                                          return Curry._1(setUserState, (function (x) {
                                                        x[name] = value;
                                                        return x;
                                                      }));
                                        })
                                    }) : React.createElement("i", undefined, "Ikke implementert");
                              return React.createElement(Card$BycratesPlatformUi.make, {
                                          children: null,
                                          className: "text-left" + (
                                            List.find_opt((function (x) {
                                                    return Caml_obj.caml_equal(x, field.id);
                                                  }), missingCards) !== undefined ? " border border-red-200" : ""
                                          ),
                                          key: String(field.id)
                                        }, React.createElement(H5$BycratesPlatformUi.make, {
                                              className: "mb-6",
                                              light: true,
                                              children: field.question
                                            }), tmp);
                            }), x.fields)), React.createElement(Button$BycratesPlatformUi.make, {
                        type_: "submit",
                        className: "mt-6 w-full",
                        label: "Send inn",
                        disabled: match$3[0]
                      })));
  }
}

var make = FormRender;

exports.make = make;
/* axios Not a pure module */
