'use strict';

var React = require("react");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var LoaderCss = require("../src-ui/loader.css");
var H1$BycratesPlatformUi = require("../src-ui/Headings/H1.bs.js");
var FormRender$BycratesPlatformUi = require("./FormRender.bs.js");
var TailwindCss = require("../src-ui/generated/tailwind.css");
var Bycrates_formsSvg = require("../src-ui/assets/bycrates_forms.svg");

var bycratesForms = Bycrates_formsSvg;

function FormsRoot(Props) {
  var url = ReasonReactRouter.useUrl(undefined, /* () */0);
  var match = url.path;
  var tmp;
  var exit = 0;
  if (match && !match[1]) {
    tmp = React.createElement(FormRender$BycratesPlatformUi.make, {
          token: match[0]
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(H1$BycratesPlatformUi.make, {
          children: "Ingen skjema funnet"
        });
  }
  return React.createElement("div", {
              className: "max-w-xl m-2 sm:m-auto sm:mt-12 text-center"
            }, tmp, React.createElement("a", {
                  className: "block pb-6",
                  href: "https://www.bycrates.com/nb",
                  target: "_blank"
                }, React.createElement("img", {
                      className: "h-4 mt-8 m-auto",
                      src: "https://bycratescdn.com/logos/bycrates-higo.svg"
                    })));
}

var tailwind = /* () */0;

var loader = /* () */0;

var make = FormsRoot;

exports.tailwind = tailwind;
exports.loader = loader;
exports.bycratesForms = bycratesForms;
exports.make = make;
/*  Not a pure module */
